/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCraft } from "./query";
import ContentTemplate from "@components/ContentTemplate";

const SubmissionGuidelines = ({ location }) => {
  const { title, description, body } = useCraft();

  return (
    <ContentTemplate
      title={title}
      description={description}
      body={body}
      bgIcon="send"
      location={location}
    />
  );
};

export default SubmissionGuidelines;
